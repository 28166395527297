import { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from './../../contexts';

import {
  Box,
  Button,
  CircularProgress,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import CustomAlert from '../../components/NewCustomAlert';
import { TextField } from '../../components/fields';
import SmallLogo from '../../components/layout/SmallLogo';
import { SHOW_PARENT_MODAL, TOKEN } from '../../constants';
import { useStyles } from './styles';

import client from '../../apollo';
import { cjConfig, handleMutationError } from '../../common/utility';
import { customTheme } from '../MaterializeCss';
import { GET_USER_FROM_USER_TOKEN } from '../main/intakeForm/gql';
import { getUserFromUserTokenQuery } from '../main/intakeForm/gql/__generated__/getUserFromUserTokenQuery';
import { LoginFormSchema } from './LeadRegistration/lib/validationSchemas';
import { LOGIN_WITH_EMAIL_MUTATION } from './LeadRegistration/gql';
import {
  SignInWithEmailMutation,
  SignInWithEmailMutationVariables,
} from './LeadRegistration/gql/__generated__/SignInWithEmailMutation';
import AccountLockedAlert from '../../common/AccountLockedAlert';

interface Props extends RouteComponentProps {
  _goback?: string | null;
}

const NewLoginForm = ({ _goback }: Props): JSX.Element => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const userEmail = urlParams.get('email') || ''; // take email from params
  const { setIsLoggedIn } = useContext(AppContext);
  const history = useHistory();
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [userToken, setUserToken] = useState('');
  const [authError, setAuthError] = useState<string>('');
  const classes = useStyles();
  const [loginInitialValues, setloginInitialValues] = useState({
    email: userEmail,
    password: '',
  });
  const toggleAlert = (variant?: string, message?: string) => {
    setVariant(variant || '');
    setMessage(message || '');
  };

  const fetchUser = async () => {
    const { data }: { data: getUserFromUserTokenQuery } = await client.query({
      query: GET_USER_FROM_USER_TOKEN,
      variables: {
        userToken: userToken,
      },
      fetchPolicy: 'network-only',
    });
    if (data && data?.getUserFromUserToken?.user) {
      const { email } = data?.getUserFromUserToken?.user;
      setloginInitialValues({ ...loginInitialValues, email: email });
    }
  };

  const mountEffectOnce = () => {
    token && setUserToken(token);
    if (userToken) fetchUser();
    cjConfig({});
  };

  useEffect(mountEffectOnce, [userToken]);
  const params = new URLSearchParams(window.location.search);
  const isUpcoming = params.get('upcoming');
  const _gobackU = params.get('_goback');
  const webinarId = _gobackU?.split('/')[2];

  useEffect(() => {
    const handleBackButton = () => {
      if (window.location.search.includes('ranking')) {
        history.replace('/colleges');
      }
      window.removeEventListener('popstate', handleBackButton, true);
    };

    window.addEventListener('popstate', handleBackButton, true);
  }, []);

  return (
    <>
      <CustomAlert
        variant={variant}
        message={message}
        toggleAlert={toggleAlert}
      />

      <Mutation<SignInWithEmailMutation, SignInWithEmailMutationVariables>
        mutation={LOGIN_WITH_EMAIL_MUTATION}
      >
        {(signInWithEmailMutation: Function) => {
          return (
            <Formik
              enableReinitialize
              initialValues={loginInitialValues}
              validationSchema={LoginFormSchema}
              onSubmit={async (variables, { setSubmitting }) => {
                try {
                  const result = await signInWithEmailMutation({
                    variables,
                  });
                  const {
                    data: {
                      signInWithEmail: { token, isParent, lastLoginAt },
                    },
                  } = result;

                  const firstTimeLogin = lastLoginAt ? false : true;
                  localStorage.setItem(
                    'firstTimeLogin',
                    String(firstTimeLogin)
                  );
                  localStorage.setItem(TOKEN, token);
                  setIsLoggedIn(true);
                  localStorage.setItem(SHOW_PARENT_MODAL, 'true');
                  if (isParent) {
                    history.push('/');
                  }
                  if (
                    _goback?.includes('webinar') &&
                    isUpcoming === 'true' &&
                    !isParent
                  ) {
                    history.push(`webinar/${webinarId}?r=t`);
                  }
                } catch (error) {
                  setSubmitting(false);
                  handleMutationError(error, (errorMessage) => {
                    setAuthError(errorMessage.message);
                  });
                }
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Box maxWidth={390} margin="0 auto" width="100%">
                    <Box display="flex" alignItems="center" marginBottom={4}>
                      <Box
                        maxWidth={48}
                        marginRight={2}
                        className="hide-mobile"
                      >
                        <SmallLogo />
                      </Box>

                      <Box component="h4">Welcome back!</Box>
                    </Box>
                  </Box>

                  <Box maxWidth={390} margin="0px auto">
                    {authError && (
                      <Fragment>
                        {authError.includes('ACCOUNT_LOCKED') ? (
                          <AccountLockedAlert
                            title="Your Account Has Been Temporarily Locked"
                            subTitle={
                              <Fragment>
                                {' '}
                                Password attempt limit reached. Regain access by{' '}
                                <Link to="/forgot-password">
                                  resetting your password
                                </Link>{' '}
                                and following the &nbsp; emailed instructions.
                                Contact success@collegeadvisor.com for
                                additional support.
                              </Fragment>
                            }
                          />
                        ) : (
                          <AccountLockedAlert
                            title={authError?.split('-')[0]}
                            subTitle={
                              <Fragment>{authError?.split('-')[1]}</Fragment>
                            }
                          />
                        )}
                      </Fragment>
                    )}
                    <Field
                      type="email"
                      name="email"
                      component={TextField}
                      label="Email"
                      fullWidth
                      variant="filled"
                      className={classes.textfield}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: { value },
                        } = e;
                        setFieldValue('email', value);
                      }}
                      disabled={userEmail ? true : false}
                    />

                    <Field
                      type="password"
                      name="password"
                      component={TextField}
                      label="Password"
                      fullWidth
                      variant="filled"
                      className={classes.textfield}
                      onChange={(e: FormEvent<HTMLInputElement>) => {
                        const {
                          currentTarget: { value },
                        } = e;
                        setFieldValue('password', value);
                      }}
                    />
                    <ThemeProvider theme={customTheme}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                        fullWidth
                        color="secondary"
                        id="login-button"
                      >
                        Login
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    </ThemeProvider>
                  </Box>
                </Form>
              )}
            </Formik>
          );
        }}
      </Mutation>
      <Box textAlign="center">
        <Link
          to={`${_goback ? `/forgot-password${_goback}` : '/forgot-password'}`}
          className={classes.forgotPass}
        >
          Forgot password?
        </Link>
      </Box>

      <Typography variant="body2" className="loggingText">
        Still having issues logging in? Email us at
        <br />
        <a href="mailto:success@collegeadvisor.com">
          success@collegeadvisor.com.
        </a>
      </Typography>

      <Box className={`${classes.isLoggedin} customized loginCustomizedText`}>
        Don’t have an account?
        <Link
          to={`${_goback ? `/register${_goback}` : '/register'}`}
          id="go-to-signup"
        >
          Sign Up
        </Link>
      </Box>
    </>
  );
};

export default withRouter(NewLoginForm);
