import gql from 'graphql-tag';

export const STUDENT_ALL_APPLICATION_ESSAY_QUERY = gql`
  query getStudentApplicationsEssayQuery(
    $studentId: Int
    $limit: Int
    $pageNumber: Int
    $searchQuery: String
    $fromMyApplicationsPage: Boolean
  ) {
    getStudentApplicationEssays(
      studentId: $studentId
      limit: $limit
      pageNumber: $pageNumber
      searchQuery: $searchQuery
      fromMyApplicationsPage: $fromMyApplicationsPage
    ) {
      totalCount
      result {
        essay {
          id
          documentUrl
          category
          college {
            id
            name
            logo
          }
          description
          longDescription
          name
          date
          maxWord
          title
        }
      }
    }
  }
`;

export const STUDENT_DOCUMENTS_QUERY = gql`
  query getStudentDocuments(
    $page: Int
    $categoryFilter: String
    $searchQuery: String
    $limit: Int
    $fromMyApplicationsPage: Boolean
  ) {
    studentDocuments(
      input: {
        page: $page
        categoryFilter: $categoryFilter
        searchQuery: $searchQuery
        limit: $limit
        fromMyApplicationsPage: $fromMyApplicationsPage
      }
    ) {
      studentDocuments {
        id
        documentUrl
        documentName
        category
        createdAt
        essayTitle
        College {
          id
          name
          logo
        }
        wordMax
        essayPrompt
      }
      nextRecord
      studentDocumentsCount
    }
  }
`;

export const STUDENT_APPLICATION_QUERY = gql`
  query StudentApplicationDetailNewQuery($studentApplicationId: Int!) {
    StudentApplication(studentApplicationId: $studentApplicationId) {
      id
      applicationStatus
      decisionStatus
      decisionDeadline
      graduatedEarlyActionDeadline
      graduatedRegularDecisionDeadline
      graduatedEarlyDecisionDeadline
      essayPromptsChanged
      decision
      isBSMD
      ApplicationCampusesSurvey {
        id
        name
        logo
        decision
      }
      commonEssays {
        id
        name
        status
        documentUrl
        completedAt
        essayStatus
        essayTemplate {
          id
          required
          commonEssay
          lengthLimit
          specificSchool
          requirementNote
          longDescription
          shortDescription
          essayTitle
          deletedAt
          Order
        }
      }
      otherApplicationDeadlines {
        id
        DeadlineName
        DeadlineDate
      }
      studentApplicationCampuses {
        id
        name
        logo
        styleConfig {
          bgDark
          bgLight
        }
      }
      applicationRequirement {
        id
        year
        acceptsCommonApp
        acceptsCoalitionApp
        earlyActionDeadline
        earlyDecisionDeadline
        offersEarlyDecision
        offersEarlyAction
        regularDecisionDeadline
        college {
          id
          name
          customCollege
          admissionPageUrl
          logo
          isUCCollege
          styleConfig {
            bgDark
            bgLight
          }
          parentMetaData
          Campuses {
            id
            name
            logo
          }
          allMajors {
            id
            name
            otherMajor
            majorCategory {
              id
              name
              displayName
            }
          }
        }
      }
      studentApplicationEssays {
        id
        name
        status
        documentUrl
        completedAt
        essayStatus
        essayTemplate {
          id
          required
          commonEssay
          lengthLimit
          specificSchool
          requirementNote
          longDescription
          shortDescription
          essayTitle
          deletedAt
          Order
        }
      }
      studentApplicationMajors {
        id
        name
        MajorId
        otherMajor
        ApplicationCampusId
        majorCategory {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_STUDENT_APPLICATIONS_CHECKLIST = gql`
  query FetchStudentAppChecklist($studentApplicationId: Int!) {
    fetchStudentAppChecklist(studentApplicationId: $studentApplicationId) {
      id
      StudentApplicationId
      accountCreated
      testScoresSubmitted
      transcriptRequested
      recommendationLetterRequested
      otherNotes
      accountCreatedCompletedAt
      testScoresSubmittedCompletedAt
      transcriptRequestedCompletedAt
      recommendationLetterRequestedCompletedAt
      otherNotesCompletedAt
    }
  }
`;

export const UPDATE_APPLICATION_STATUS_AND_DEADLINE = gql`
  mutation UpdateApplicationStatusAndDeadline(
    $decisionStatus: String
    $decisionDeadline: String
    $StudentApplicationId: Int!
    $userToken: String
  ) {
    updateApplicationStatusAndDeadline(
      input: {
        decisionStatus: $decisionStatus
        decisionDeadline: $decisionDeadline
        StudentApplicationId: $StudentApplicationId
        userToken: $userToken
      }
    ) {
      success
      message
    }
  }
`;

export const APP_COLLEGE_QUERY = gql`
  query AppCollegeQuery {
    allStudentApplications {
      StudentApplications {
        id
        applicationRequirement {
          id
          college {
            id
            name
            logo
          }
        }
      }
    }
  }
`;

export const SET_STUDENT_APPLICATION_MAJORS = gql`
  mutation setStudentApplicationMajors(
    $applicationId: Int!
    $majors: [StudentApplicationMajorInput]
  ) {
    setStudentApplicationMajors(
      applicationId: $applicationId
      majors: $majors
    ) {
      success
      message
    }
  }
`;

export const SET_BSMD_STATUS_MUTATION = gql`
  mutation SetBsmdStatusMutation($id: Int!) {
    setBsmdStatus(id: $id) {
      success
    }
  }
`;
