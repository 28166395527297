// imports
import { FC, Fragment, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
// styles
import { homePageStyles } from './styles';
// images
import InfoIcon from '../../../img/error-ic.svg';
import { MilestoneAlertProps } from '../../../common/interfaces';

const BannerMessage: FC<MilestoneAlertProps> = ({
  highSchoolYear,
}) => {
  const classes = homePageStyles();

  return (
    <Box className={classes.delinquentPayment}>
      <img src={InfoIcon} alt="info" />

      <Box width="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">
            Your account balance is overdue.
          </Typography>
        </Box>

        <Typography variant="body1">
          Booking advisor meetings and milestone teams will be temporarily disabled  until the past-due balance is paid. If you have any question, please contact us at <a href="mailto:success@collegeadvisor.com" className="emailto">success@collegeadvisor.com</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default BannerMessage;
