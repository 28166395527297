import gql from 'graphql-tag';

export const GET_USER_FROM_USER_TOKEN_MAIN = gql`
  query getUserFromUserTokenMainQuery($userToken: String!) {
    getUserFromUserToken(input: { userToken: $userToken }) {
      user {
        firstName
        lastName
        id
        isPremium
        isParent
        isMBAGrad
      }
    }
  }
`;
