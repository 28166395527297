import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Colors from '../../common/colors';
import fonts from '../../common/fonts';
import colors from '../../common/colors';

export const homePageStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountLockedAlert: {
      display: 'flex',
      borderRadius: 15,
      marginBottom: 45,
      alignItems: 'flex-start',
      padding: '20px 16px 15px',
      background: colors.RED_LIGHT,

      '& img': {
        marginRight: 10,
      },

      "& [class*='MuiTypography-h4']": {
        padding: 0,
        fontSize: 14,
        maxWidth: 309,
        fontWeight: 600,
        wordBreak: 'break-all',
        letterSpacing: '-0.084px',
        color: colors.BLACK_FOURTEEN,
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '24px',
        margin: '4px 0px 0px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        color: Colors.BLACK_FOURTEEN,

        '& a': {
          color: Colors.BLACK_FOURTEEN,
          borderBottom: `1px solid ${Colors.BLACK_FOURTEEN}`,
        },
      },

      "& [class*='MuiIconButton-root']": {
        padding: 6,
      },
    },

    mbaProgram: {
      '& .buttonContained': {
        minWidth: 103,
        marginRight: '18px !important ',
      },

      '& a': {
        fontSize: 12,
        fontWeight: 400,
        color: '#7D7F7F',
        letterSpacing: '-0.072',
        fontFamily: fonts.INTER,
      },

      '& .callus': {
        paddingTop: 2,
        color: '#7D7F7F',
      },

      '& .telephone': {
        color: '#2F80ED',
        letterSpacing: '-0.072',
        borderBottom: '1px solid #2F80ED',
      },
    },

    upperClassHeader: {
      padding: '75px 0px 42px',

      '@media only screen and (max-width: 480px)': {
        padding: '34px 0px',
      },

      '& .graduateResponsive': {
        [`@media only screen and (max-width: 450px)`]: {
          flexDirection: 'column',
        },
      },

      '& .graduateResponsivePadding': {
        [`@media only screen and (max-width: 450px)`]: {
          paddingBottom: 15,
        },
      },
    },

    graduatedBox: {
      background: '#FFCD4B',
      borderRadius: 6,
      width: 112,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        width: 12,
        marginRight: 8,
      },

      '& p': {
        fontWeight: 600,
        fontSize: 14,
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },
    },

    homeScreenContainer: {
      '& .MuiGrid-item': {
        width: '100%',
      },
    },

    packageCardDesktop: {
      '@media only screen and (max-width: 1024px)': {
        display: 'none',
      },
    },

    accessGoogleDrive: {
      display: 'flex',
      alignItems: 'center',
      padding: '21px 30px',
      marginBottom: 29,
      border: '1px solid #D4D4D4',
      borderRadius: 25,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [`@media only screen and (max-width: 414px)`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .accessText': {
        flex: 1,
        marginLeft: 20,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#272929',
        fontFamily: fonts.INTER,

        [`@media only screen and (max-width: 414px)`]: {
          margin: '15px 0px 5px',
        },
      },
    },
    advisorMatchCard: {
      border: 0,
      padding: '16px 16px 20px',
      marginBottom: 61,
      boxShadow: 'none',
      background: '#EBF3FE',

      '@media only screen and (max-width: 767px)': {
        padding: '15px',
        margin: 15,
      },

      '& h5, button': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& h5': {
        fontSize: 14,
        color: '#272929',
        fontWeight: 600,
        marginBottom: 4,
        letterSpacing: '-0.006em',
      },

      '& p': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 12,
        color: '#7D7F7F',
        letterSpacing: '-0.006em',
      },

      '& button': {
        borderRadius: 12,
        height: 32,
        minWidth: 164,
        fontWeight: 700,
        padding: '8px 15px',
        textTransform: 'capitalize',
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,

        '& span': {
          fontSize: 14,
        },
      },

      '& .buttonContained': {
        marginRight: 12,
        color: Colors.WHITE_ONE,
      },

      '& .buttonOutlined': {
        color: '#272929',
        border: '1px solid #D4D4D4',

        '&:hover': {
          color: '#272929',
          border: '1px solid #D4D4D4',
          backgroundColor: Colors.WHITE_ONE,
        },
      },
    },

    activityPendingCard: {
      border: 0,
      maxWidth: 880,
      marginBottom: 61,
      boxShadow: 'none',
      padding: '28px 16px',
      background: '#EDFBF5',

      '@media only screen and (max-width: 767px)': {
        padding: '15px',
        margin: 15,
      },

      '& h5, button': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& h5': {
        fontSize: 14,
        color: '#272929',
        fontWeight: 600,
        marginBottom: 4,
        letterSpacing: '-0.006em',
      },

      '& p': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 12,
        color: '#7D7F7F',
        letterSpacing: '-0.006em',
      },

      '& button': {
        borderRadius: 12,
        height: 32,
        minWidth: 164,
        fontWeight: 700,
        padding: '8px 15px',
        textTransform: 'capitalize',
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,

        '& span': {
          fontSize: 14,
        },
      },

      '& .buttonContained': {
        marginRight: 12,
        color: Colors.WHITE_ONE,
      },

      '& .buttonOutlined': {
        color: '#272929',
        border: '1px solid #D4D4D4',

        '&:hover': {
          color: '#272929',
          border: '1px solid #D4D4D4',
          backgroundColor: Colors.WHITE_ONE,
        },
      },
    },
  })
);
