import { createContext, SetStateAction } from 'react';
import {
  UserQuery_currentUser_cancelAction,
  UserQuery_currentUser_childrens,
  UserQuery_currentUser_firstMeetingBookedDate,
  UserQuery_currentUser_lastLocation,
  UserQuery_currentUser_parents,
  UserQuery_currentUser_planDetail,
  UserQuery_currentUser_signedContract,
  UserQuery_currentUser_studentTrial,
  UserQuery_currentUser_userCoupon,
  UserQuery_currentUser_userLocation,
} from '../pages/main/layouts/__generated__/UserQuery';

import { AllStudentEssaysQuery_allStudentEssays } from '../pages/main/orders/__generated__/AllStudentEssaysQuery';
import { AllCollegesQuery_allColleges_colleges } from '../pages/main/shared/colleges/__generated__/AllCollegesQuery';
import { SmartMatch_smartMatch } from '../graphql/queries/smartMatch/__generated__/SmartMatch';
import { TuserfirstMeetingInfo } from '../common/interfaces';

export type User = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  pictureUrl: string | null;
  email: string;
  isParent: boolean | null;
  phoneNumber: string | null;
  highSchool: string | null;
  gapYear: boolean | null;
  highSchoolYear: string | null;
  shareDriveUrl: string | null;
  applyingYear?: string | null;
  studentTrial: UserQuery_currentUser_studentTrial | null;
  invitesSent: number | null;
  aidOption: number | null;
  gpa: string | null;
  sat: string | null;
  act: string | null;
  parents: (UserQuery_currentUser_parents | null)[] | null;
  childrens: (UserQuery_currentUser_childrens | null)[] | null;
  isPremium: boolean | null;
  premiumPackageType: string | null;
  premiumTotalEssayCount: number | null;
  premiumEssayCountUsed: number | null;
  premiumTotalVideoCount: number | null;
  premiumVideoCountUsed: number | null;
  isPackageCustom: boolean | null;
  isAppNotificationOn: boolean | null;
  roles?: (string | null)[] | null;
  userSubscription: any | null;
  lastLoginAt: string | null;
  origin: string | null;
  createdInSalesforce: boolean | null;
  unreadMessagesCount: number | null;
  isOnline: boolean | null;
  lastLocation: UserQuery_currentUser_lastLocation | null;
  timezone: string | null;
  userCoupon?: UserQuery_currentUser_userCoupon | null;
  createdAt: any;
  intakeFormToken: string | null;
  intakeResponse: JSON;
  initialKickOffDate: Date;
  lastPaymentStatus: string | null;
  graduated: boolean | null | undefined;
  firstMeetingBookedDate:
    | UserQuery_currentUser_firstMeetingBookedDate
    | TuserfirstMeetingInfo
    | null;
  userToken: string | null;
  premiumPackageColor: string | null;
  signedContract: UserQuery_currentUser_signedContract | null;
  planCancelled: boolean | null;
  clientStatus: String | null;
  accountStatus: String | null;
  isDisputed: Boolean | null;
  isPaymentFailed: Boolean | null;
  isRisk: boolean | false;
  planDetail: UserQuery_currentUser_planDetail | null;
  markedPremiumAt: Date | null;
  cancelAction: UserQuery_currentUser_cancelAction | null;
  sendbirdId: string | null;
  userLocation: UserQuery_currentUser_userLocation | null;
  isMBAGrad: boolean | null;
  undergraduateGradYear: string | null;
  undergraduateCollege: string | null;
  SmartMatchId: number | null;
  degree: string | null;
} | null;

export interface AppContextProps {
  user: User | null;
  isLoggedIn: boolean;
  unreadMessagesCount: number | null;
  showCollegeFilter: boolean;
  setUser: (user: User) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setUnreadMessagesCount: (data: any) => void;
  setShowCollegeFilter: React.Dispatch<SetStateAction<boolean>>;
  searchFromSaved: boolean;
  setSearchFromSaved: React.Dispatch<SetStateAction<boolean>>;
  fetchPlans: boolean;
  setFetchPlans: React.Dispatch<SetStateAction<boolean>>;
  smartMatch: SmartMatch_smartMatch | null;
  setSmartMatch: React.Dispatch<SetStateAction<SmartMatch_smartMatch | null>>;
  fetchSmartMatch: Function | null;
  setFetchSmartMatch: React.Dispatch<SetStateAction<Function>>;
}

export const AppContext = createContext<AppContextProps>({
  isLoggedIn: false,
  user: null,
  unreadMessagesCount: 0,
  showCollegeFilter: true,
  setUser() {},
  setIsLoggedIn() {},
  setUnreadMessagesCount() {},
  setShowCollegeFilter() {},
  searchFromSaved: false,
  setSearchFromSaved() {},
  fetchPlans: false,
  setFetchPlans() {},
  smartMatch: null,
  fetchSmartMatch() {},
  setFetchSmartMatch() {},
  setSmartMatch() {},
});

export const SmartMatchContext = createContext({});

export type OnboardUser = {
  id: number;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  highSchool?: string | null;
  highSchoolYear?: string | null;
  applyingYear?: string | null;
  referralSource?: string | null;
  roles?: (string | null)[] | null;
  gpa?: string | null;
  sat?: string | null;
  act?: string | null;
  isParent?: boolean | null;
  colleges?: (AllCollegesQuery_allColleges_colleges | null)[];
  origin?: string | null;
  createdInSalesforce?: boolean | null;
  createdAt: any;
} | null;

export interface OnboardContextProps {
  user: OnboardUser | null;
  updateUser: (user: OnboardUser) => void;
}

export const OnboardContext = createContext<OnboardContextProps>({
  user: null,
  updateUser() {},
});

export type MentorType = {
  id?: number | null;
  firstName?: string | null;
  pictureUrl?: string | null;
  email?: string | null;
  appId?: string | null;
  appName?: string | null;
  sourceId?: string | null;
  reviewType?: string | null;
  completionDate?: Date | null;
  essays?: AllStudentEssaysQuery_allStudentEssays[] | null;
  cardNumber?: string | null;
  cardBrand?: string | null;
  coupon?: string | null;
  videoItemId?: number | null;
  totalAmount?: string | null;
} | null;

export type OrderType = {
  orderItemType: string;
  StudentApplicationEssayId: number | null;
  name?: string | null;
} | null;

export interface MentorOrderProps {
  orderItems: OrderType[] | null;
  mentor: MentorType | null;
  setMentor: (mentor: MentorType, orderItems: OrderType[]) => void;
}

export const MentorOrderContext = createContext<MentorOrderProps>({
  orderItems: null,
  mentor: null,
  setMentor() {},
});
