import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FC } from 'react';
import { ILoader } from '../common/interfaces';
import Colors from '../common/colors';

import { loaderStyles } from './styles';
import { loaderTexts } from '../common/constants';

const Loader: FC<ILoader> = ({
  isSmallLoader,
  isXSLoader,
  filerZindex,
  loaderText,
  isTransparent,
  forceLoaderText,
}) => {
  const classes = loaderStyles();

  return (
    <Box
      height={!!isSmallLoader ? '100%' : '100vh'}
      minHeight={isXSLoader ? 'unset' : 200}
      width="100%"
      position={!!isSmallLoader ? 'relative' : 'absolute'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      left={0}
      top={0}
      zIndex={filerZindex ? 0 : 99}
      className={
        isTransparent ? classes.tLoaderContainer : classes.loaderContainer
      }
    >
      <CircularProgress
        className={isXSLoader ? classes.smallProgress : classes.progress}
      />

      <Box
        fontWeight={600}
        fontSize={14}
        letterSpacing="0.3375px"
        color={Colors.BLUE_SEVEN}
        display="block"
        marginTop={1.5}
        className={classes.loaderText}
      >
        {forceLoaderText
          ? loaderTexts.main
          : !isTransparent
          ? loaderText
            ? loaderText
            : loaderTexts.main
          : null}
      </Box>
    </Box>
  );
};

export default Loader;
