import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../contexts';
import Images from '../img';
import PasswordKeyIcon from '../img/key.svg';
import MyProfileIcon from '../img/my-profile-icon.svg';
import ParentAccountIcon from '../img/parent-account.svg';
import PlanBillingIcon from '../img/plan-billing.svg';
import UserProfileIcon from '../img/user-profile.svg';
import { accountMenuStyles } from './styles';

const PROFILE_NAV = [
  {
    title: 'Account Information',
    linkTo: '/account/profile',
    icon: UserProfileIcon,
  },
  {
    title: 'Login & Password',
    linkTo: '/account/change-password',
    icon: PasswordKeyIcon,
  },
  {
    title: 'My Profile',
    linkTo: '/account/my-profile',
    icon: MyProfileIcon,
  },
];

interface PropsType {
  isRisk?: boolean | false;
}

const OldAccountMenu: React.FC<PropsType> = ({isRisk}) => {
  const { accountNav, navLink, navLinkIcon } = accountMenuStyles();
  const { user } = useContext(AppContext);

  if (!user) return null;
  if (!(user?.isPremium && !user?.userSubscription)) {
    const find = PROFILE_NAV.find((nav) => {
      if (nav.title === 'Plan & Billing') {
        return nav;
      }
    });
    if (!find) {
      PROFILE_NAV.push({
        title: `Plan & Billing`,
        linkTo: '/account/billing',
        icon: PlanBillingIcon,
      });
    }
  }

  const { isParent } = user;

  return (
    <Box borderRadius={4} padding="20px 0" className={accountNav}>
      {PROFILE_NAV.map((item, index) => (
        <NavLink to={item.linkTo} key={item.title} className={navLink}>
          <img
            src={item.icon}
            alt={String(index)}
            className={navLinkIcon}
          ></img>
          {item.title}
          {item.title.toLowerCase() === 'plan & billing' &&
            user &&
            (user?.lastPaymentStatus === 'failure' || isRisk) && (
              <img src={Images.EXC_VECTOR} className="ml-2" />
            )}
        </NavLink>
      ))}

      <NavLink
        to={
          !isParent ? '/account/parent-accounts' : '/account/student-accounts'
        }
        className={navLink}
      >
        <img src={ParentAccountIcon} alt="parent" className={navLinkIcon}></img>
        {!isParent ? 'Parent/Guardian Accounts' : 'Student Accounts'}
      </NavLink>
    </Box>
  );
};

export default OldAccountMenu;
