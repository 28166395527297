import Images from '../img';

import {
  AidIcon,
  DressIcon,
  EssayIcon,
  ExperienceIcon,
  HomeIcon,
  ListIcon,
  MilestoneColorIcon,
  NetworkAlumni,
  SubmissionIcon,
  TeamIcon,
} from './assets/svgs';
import colors from './colors';

import {
  CONTACT_CARD_IC,
  EXPLORE_IC,
  PHONE_IC,
  QUESTIONAIR_IC,
} from './components';
import { IHighChartOptions } from './interfaces';

const { getNames } = require('country-list');

export const APP_PRICE = 299;
export const VIDEO_PRICE = 30;
export const ESSAY_PRICE = 98;

export const OneDayReview = 'OneDayReview';
export const TwoDayReview = 'TwoDayReview';
export const appReview = 'appReview';

export const CONTENT_MIN_HEIGHT = 'calc(100vh - 154px)';
export const CONTENT_MODAL_OVERLAY = 'calc(100vh - 172px)';
export const CURRENT_YEAR = new Date().getFullYear();

export const COLLEGE_AID_PRO = 'College Aid Pro';
export const CONSULTANT_LEVEL = 'Financial Aid Consultant';

export const InstallmentStatus = {
  PAID: 'paid',
  PENDING: 'pending',
  CANCELED: 'canceled',
};

export const PaymentStatus = {
  FAILURE: 'failure',
  PARTIAL_REFUND: 'partial_refund',
  SUCCESS: 'success',
  FULL_REFUND: 'full_refund',
  PENDING: 'pending',
};

export const cancel_values = {
  '14 Day Cancel': '14_day_cancel',
  'CollegeAdvisor Service Breakdown Cancel':
    'collegeadvisor_service_breakdown_cancel',
  'Customer Change of Heart Cancel': 'customer_change_of_heart_cancel',
  'Customer Expectations Misalignment Cancel':
    'customer_expectations_misalignment_cancel',
  'Financial / Health Hardship Cancel': 'financial_health_hardship_cancel',
  'Bad Debt Cancel': 'bad_debt_cancel',
  'Chargeback Cancel': 'chargeback_cancel',
  'Early Termination Fee': 'early_termination_fee',
};

export const MILESTONE_BODY = `Request additional assistance from expert advisors specializing in various areas of the admissions journey.`;

export const MILESTONE_HEADING = `CollegeAdvisor.com Milestone Teams`;

export const DisputeStatus = {
  OPENED: 'needs_response',
  UNDER_REVIEW: 'under_review',
  WON: 'won',
  LOST: 'lost',
};

export const disputeStatusAlt: any = {
  needs_response: 'dispute_opened',
  under_review: 'under_review',
  won: 'dispute_won',
  lost: 'dispute_lost',
};

export const ClientStatus = {
  ACTIVE: 'Active - Good Standing',
  FAILED: 'Active - Payment Grace',
  CANCELED: 'Canceled',
  FREE: 'Free',
  VINTAGE: 'Vintage Enrollee',
  BAD_DEBT: 'Bad Debt Cancel',
  CAP: 'CAP Enrollee',
};

export const meetingStatuses = {
  cancel: 'Cancelled',
  join: 'Join',
  upcoming: 'Upcoming',
  end: 'End',
  noShow: 'No show',
  complete: 'Completed',
  actualMeeting: 'Actual Meeting',
  reschedulePending: 'Reschedule Pending',
  showRescheduleBanner(meetingStatus: string) {
    return [this.cancel, this.noShow, this.reschedulePending].includes(
      meetingStatus
    );
  },
};

export const meetingStages = {
  followUpTentative: 'Follow Up - Tentative',
  followUpScheduled: 'Follow Up - Scheduled',
  closedLost: 'Closed Lost',
};

export const preMeetingTexts = {
  meetingCancel: `We missed you! Let's get in touch!`,
  meetingEnd: 'Select Your Package',
  meetingJoin: "It's time to meet!",
  upcomingMeeting: 'Your upcoming consultation',
  meetingRescheduleText:
    'Click the button below to reschedule your free consultation and learn how CollegeAdvisor.com can help you reach your dream school!',
  meetingJoinText: 'Access the Zoom call by clicking the button below!',
  meetingEndText:
    'Thanks for scheduling a meeting with us at CollegeAdvisor.com. View your admissions package options by clicking the link below.',
  upcomingMeetingText: `Thanks for scheduling a meeting with us at CollegeAdvisor.com. We're looking forward to joining you at the time below:`,
  closedLostText:
    'Thanks for taking the time to meet with us. Feel free to access your options by clicking the button below!',
};

export const OriginalRefundStatus = {
  PENDING: 'pending',
  SUCCESS: 'succeeded',
};

export const NewRefundStatus = {
  PENDING: 'Refund Pending',
  SUCCESS: 'Refunded',
};
export const PackageShortNames = {
  DIAMOND: 'Diamond',
  IVY: 'Ivy Plus',
  PLATINUM: 'Platinum',
  ELITE: 'Elite',
  PREMIUM: 'Premium',
};

export const UserActivityActions = {
  VISIT: 'visit',
  DEMO: 'DisplayedProductDemo',
  VIEW: 'view',
  CLICKED_APP: 'ClickedMyApplications',
  CLICKED_SCHOOL: 'ClickedExploreSchool',
  DEMO_WATCH: 'watchedCompleteProductDemo',
  SEARCH: 'search',
  DEMO_PLAY: 'autoPlayedProductDemo',
  CLICKED_WEBINAR: 'ClickedWebinar',
  CLICKED_TOURS: 'ClickedVideoTours',
  CLICKED_ESSAY: 'ClickedEssayRequirrements',
  CLICKED_ADVISOR: 'ClickedAdvisor',
  PLAY: 'play',
  CLICKED_MILESTONE_TEAM: 'ClickedMilestoneTeam',
  CLICKED_RANKING_CATEGORY: 'ClickedRankingCategory',
  CLICKED_OPPORTUNITY_RFI: 'ClickedOpportunityRFI',
};

export const UserActivityType = {
  WEBINAR: 'Webinar',
  COLLEGE_HUB: 'CollegeHubPages',
  SUMMER_OP: 'ExtracurricularOpportunity',
  PRODUCT_DEMO: 'ProductDemo',
  COLLEGE_SEARCH: 'CollegeSearch',
  MILESTONE_TEAM: 'MilestoneTeam',
  CHECKLIST: 'Checklist',
  COLLEGE_RANKINGS: 'CollegeRankings',
};

export const getCollegeMenuNav = (
  advisorsCount: number,
  webinarsCount: number,
  blogsCount: number
) => [
  {
    title: 'About',
    linkTo: `about`,
    count: -1,
  },
  {
    title: 'Admissions',
    linkTo: `admissions`,
    count: -1,
  },
  {
    title: 'Cost & Financial Aid',
    linkTo: `cost`,
    count: -1,
  },
  {
    title: 'Students',
    linkTo: `students`,
    count: -1,
  },
  {
    title: 'Academics',
    linkTo: `academics`,
    count: -1,
  },
  {
    title: 'Majors',
    linkTo: `majors`,
    count: -1,
  },
  {
    title: 'Advisors',
    linkTo: `advisors`,
    count: advisorsCount,
  },
  {
    title: 'Webinars ',
    linkTo: `webinars`,
    count: webinarsCount,
  },
  {
    title: 'Virtual Tours',
    linkTo: `virtualTours`,
    count: -1,
  },
  {
    title: 'Essay Guides & Articles',
    linkTo: `essayGuides`,
    count: blogsCount,
  },
  {
    title: 'Similar Schools',
    linkTo: `similarSchools`,
    count: -1,
  },
];
// MBA_CONSTANT
export const WELCOME_INSTRUCTIONS = (
  isMBAGrad: boolean,
  isSmartMatchId: boolean
) => {
  const data = [];

  if (!isMBAGrad && !isSmartMatchId) {
    data.push({
      image: QUESTIONAIR_IC(),
      title: 'Complete intake forms.',
      description:
        'Help us match you with the perfect advisor. Fill out your parent and student intake forms so that we can get to know you better.',
    });
  }

  if (!isSmartMatchId) {
    data.push({
      image: CONTACT_CARD_IC(),
      title: 'Get matched with your advisor.',
      description: `Within 48 hours, you’ll be matched with one of our ${
        isMBAGrad ? 'MBA' : '350+'
      } Admissions Experts. Check your inbox—you don’t want to miss this email!`,
    });
    data.push({
      image: EXPLORE_IC(),
      title: 'Explore our resources.',
      description: isMBAGrad
        ? 'CollegeAdvisor.com offers a broad selection of watchable and readable content designed to help families navigate the admissions process. '
        : 'CollegeAdvisor.com offers a broad selection of webinars, essay guides, articles, and other content designed to help families navigate the admissions process.',
    });
  }

  data.push(
    ...[
      {
        image: PHONE_IC(),
        title: 'Attend your orientation call.',
        description: isMBAGrad
          ? 'Meet your advisor, learn about the admissions process, and discuss next steps.'
          : 'No need to dress up, but we encourage the whole family to be present to meet your advisor, learn about the admissions process, and discuss next steps.',
      },
    ]
  );

  if (isSmartMatchId) {
    data.push({
      image: CONTACT_CARD_IC(),
      title: 'Complete your student profile.',
      description:
        'After your first call, ensure you obtain the most personalized assistance by sharing your experiences and achievements on the Student Portal.',
    });
  }
  return data;
};

export const associationTypeOptions = [
  {
    label: 'BSMD/Pre-Health Program',
    value: 'BSMD Program',
  },
  {
    label: 'Diamond Package',
    value: 'diamond package',
  },
  {
    label: 'Other Additional Advisor',
    value: 'others',
  },
  {
    label: 'Partner - Leadership Initiatives',
    value: 'Partner - Leadership Initiatives',
  },
];

export const bookingInfoStatuses = {
  CANCELLED: 'cancelled',
  DELETED: 'deleted',
  COMPLETED: 'completed',
};

export const optionsDecision = [
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Denied', label: 'Denied' },
  { value: 'Deferred', label: 'Deferred' },
  { value: 'Waitlisted', label: 'Waitlisted' },
];

export const MILESTONE_LIST = [
  {
    icon: Images.Milestone1Icon,
    title: 'School Exploration Team',
  },
  {
    icon: Images.Milestone4Icon,
    title: 'Financial Aid Team',
  },

  {
    icon: Images.Milestone3Icon,
    title: 'College List Team',
  },
  {
    icon: Images.Milestone6Icon,
    title: 'Dress Rehearsals',
  },
  {
    icon: Images.Milestone5Icon,
    title: 'Interview Coaching Team',
  },
  {
    icon: Images.Milestone8Icon,
    title: 'Post-Submission Support',
  },
  {
    icon: Images.Milestone7Icon,
    title: 'Experience Team',
  },
  {
    icon: Images.NETWORK,
    title: 'Network of Students and Alumni',
  },
  {
    icon: Images.Milestone2Icon,
    title: 'Essay Review Team',
  },
  {
    icon: Images.MILESTONE_DARK_IMG,
    title: 'Extracurricular Development Team',
  },
];

export const ACT_SAT_LIST = [
  {
    title: 'Thousands of timed practice questions',
  },
  {
    title: 'Video lessons',
  },

  {
    title: 'Progress tracking',
  },
];

export const FaqQuestionsData = [
  {
    icon: HomeIcon,
    title: 'School Exploration Team',
  },
  {
    icon: EssayIcon,
    title: 'Essay Review Team',
  },
  {
    icon: ListIcon,
    title: 'College List Team',
  },
  {
    icon: AidIcon,
    title: 'Financial Aid Team',
  },
  {
    icon: TeamIcon,
    title: 'Interview Coaching Team',
  },
  {
    icon: DressIcon,
    title: 'Dress Rehearsals',
  },
  {
    icon: ExperienceIcon,
    title: 'Experience Team',
  },
  {
    icon: SubmissionIcon,
    title: 'Post-Submission Support',
  },
  {
    icon: NetworkAlumni,
    title: 'Network of Students and Alumni',
  },
  {
    icon: MilestoneColorIcon,
    title: 'Extracurricular Development Team',
  },
];

export const US_STATES_NAMES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const COUNTRY_NAMES = getNames();

export const convertUnixToDate = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000);

  // Format the date as "Wed Mar 1, 3:00pm" in the user's local timezone
  const formattedDate = date.toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    // timeZoneName: 'shortGeneric',
  });

  return formattedDate;
};

export /**
 * Titles essay description
 * @param title
 * @returns string
 */
const essayDescription = (title: string) => {
  return `${title} are the most
  recent ones we can find for this school. Please
  check on this college's website for potential
  updates.`;
};

export const essayStatus = [
  {
    key: 'NOT STARTED',
    value: 'notStarted',
    className: 'statusMenu notStarted',
  },
  {
    key: 'IN PROGRESS',
    value: 'inProgress',
    className: 'statusMenu inProgress',
  },
  {
    key: 'REVIEW READY',
    value: 'reviewReady',
    className: 'statusMenu reviewReady',
  },
  { key: 'Stuck', value: 'stuck', className: 'statusMenu stuck' },
  { key: 'Completed', value: 'complete', className: 'statusMenu Completed' },
];

export const highChartOptions = ({
  percent_classes_small_raw,
  percent_classes_medium_raw,
  percent_classes_large_raw,
}: IHighChartOptions) => ({
  chart: {
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      colors: ['#1E5298', '#45CE93', '#FDBD4E'],
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      type: 'pie',
      name: 'Class breakdown',
      data: [
        {
          name: 'SMALL CLASS',
          y: percent_classes_small_raw,
        },
        {
          name: 'MEDIUM CLASS',
          y: percent_classes_medium_raw,
        },
        {
          name: 'LARGE CLASS',
          y: percent_classes_large_raw,
        },
      ],
    },
  ],
});

export const MEETING_PAGE_VIDEO_URL =
  'https://player.vimeo.com/video/861767527?badge=0&autopause=0&player_id=0&app_id=58479';
export const tealiumConstants = {
  SITE: 'collegeadvisor',
  HOMEPAGE: 'homepage', // #1
  HOMEPAGE_PRODUCT: 'homepage',
  HOMEPAGE_PRODUCT_SECTION: 'sectionfront',
  HOMEPAGE_PRODUCT_SUBSECTION: 'post_login',
  HOMEPAGE_PAGE_TYPE: 'sectionfront',
  HOMEPAGE_CONTENT_TYPE: 'product',
  ABOUT: 'about', // #2
  ABOUT_PRODUCT: 'company',
  ABOUT_PRODUCT_SECTION: 'sectionfront',
  ABOUT_PAGE_TYPE: 'sectionfront',
  ABOUT_CONTENT_TYPE: 'product',
  WEBINARS: 'webinar', // #3
  WEBINARS_PRODUCT: 'webinar',
  WEBINARS_PRODUCT_SECTION: 'sectionfront',
  WEBINARS_PAGE_TYPE: 'sectionfront',
  WEBINARS_CONTENT_TYPE: 'product',
  COLLEGES: 'colleges', // #4
  COLLEGES_PRODUCT: 'colleges',
  COLLEGES_PRODUCT_SECTION: 'sectionfront',
  COLLEGES_PAGE_TYPE: 'sectionfront',
  COLLEGES_CONTENT_TYPE: 'product',
  COLLEGE: 'colleges', // #5
  COLLEGE_PRODUCT: 'colleges',
  COLLEGE_PRODUCT_SECTION: 'profile',
  COLLEGE_PAGE_TYPE: 'profile',
  COLLEGE_CONTENT_TYPE: 'product',
  APPLICATIONS: 'applications', // #6
  APPLICATIONS_PRODUCT: 'account',
  APPLICATIONS_PRODUCT_SECTION: 'sectionfront',
  APPLICATIONS_PRODUCT_SUBSECTION: 'post_login',
  APPLICATIONS_PAGE_TYPE: 'sectionfront',
  APPLICATIONS_CONTENT_TYPE: 'product',
  APPLICATION: 'resources', // #7
  APPLICATION_PRODUCT: 'applications',
  APPLICATION_PRODUCT_SECTION: 'article',
  APPLICATION_PAGE_TYPE: 'article',
  APPLICATION_CONTENT_TYPE: 'editorial',
  ADVISORS: 'about', // #8
  ADVISORS_PRODUCT: 'advisors',
  ADVISORS_PRODUCT_SECTION: 'directory',
  ADVISORS_PAGE_TYPE: 'directory',
  ADVISORS_CONTENT_TYPE: 'product',
  ADVISOR: 'about', // #9
  ADVISOR_PRODUCT: 'advisors',
  ADVISOR_PRODUCT_SECTION: 'profile',
  ADVISOR_PAGE_TYPE: 'profile',
  ADVISOR_CONTENT_TYPE: 'product',
  EXTRACURRICULAR: 'resources', // #10
  EXTRACURRICULAR_PRODUCT: 'extracurricular',
  EXTRACURRICULAR_PRODUCT_SECTION: 'sectionfront',
  EXTRACURRICULAR_PAGE_TYPE: 'sectionfront',
  EXTRACURRICULAR_CONTENT_TYPE: 'product',
  EXTRACURRICULAR_SEARCH: 'resources', // #10
  EXTRACURRICULAR_SEARCH_PRODUCT: 'extracurricular',
  EXTRACURRICULAR_SEARCH_PRODUCT_SECTION: 'search',
  EXTRACURRICULAR_SEARCH_PRODUCT_SUBSECTION: '',
  EXTRACURRICULAR_SEARCH_PAGE_TYPE: 'search',
  EXTRACURRICULAR_SEARCH_CONTENT_TYPE: 'product',
  WEBINAR: 'webinar', // #11
  WEBINAR_PRODUCT: 'webinar',
  WEBINAR_PRODUCT_SECTION: 'sectionfront',
  WEBINAR_PAGE_TYPE: 'sectionfront',
  WEBINAR_CONTENT_TYPE: 'product',
  CHAT: 'account', // #12
  CHAT_PRODUCT: 'account',
  CHAT_PRODUCT_SECTION: 'chat',
  CHAT_PRODUCT_SUBSECTION: 'post_login',
  CHAT_PAGE_TYPE: 'chat',
  CHAT_CONTENT_TYPE: 'product',
  PROFILE: 'account', // #13
  PROFILE_PRODUCT: 'account',
  PROFILE_PRODUCT_SECTION: 'user_settings',
  PROFILE_PRODUCT_SUBSECTION: 'post_login',
  PROFILE_PAGE_TYPE: 'user_settings',
  PROFILE_CONTENT_TYPE: 'product',
  CHECKLIST: 'checklist', // #14
  CHECKLIST_PRODUCT: 'account',
  CHECKLIST_PRODUCT_SECTION: 'directory',
  CHECKLIST_PRODUCT_SUBSECTION: 'post_login',
  CHECKLIST_PAGE_TYPE: 'directory',
  CHECKLIST_CONTENT_TYPE: 'product',
  MYWEBINAR: 'webinar', // #15
  MYWEBINAR_PRODUCT: 'account',
  MYWEBINAR_PRODUCT_SECTION: 'registered_webinar',
  MYWEBINAR_PRODUCT_SUBSECTION: 'post_login',
  MYWEBINAR_PAGE_TYPE: 'registered_webinar',
  MYWEBINAR_CONTENT_TYPE: 'product',
};

export const MBAPackageShortNames = {
  EDGE: 'MBA Edge',
  PLUS: 'MBA Plus',
};

export const roles = {
  STUDENT: 'Student',
  Parent: 'Parent',
};

export const Steps = {
  INTERESTED_STEP: 0,
  SCHOOLS_APPLYING: 1,
  ADVISOR_PREFERENCES: 2,
};

export const DEAFULT_RANKING_CATEGORY = 'National Universities';

export const QUERY_PARAMS = {
  RANKING_NAME: 'rankingName',
  RANKING_CATEGORY_ID: 'rankingCategoryId',
  RANKING_SUB_CATEGORY_NAME: 'rankingSubCategoryName',
  RANKING_SUB_CATEGORY_ID: 'rankingSubCategoryId',
};

export const loaderTexts = {
  main: 'Loading, PLEASE WAIT...',
};
export const POLYGENCE_PACKAGE = 'polygence';

export const EXTRA_SUPPORT_URL =
  'https://airtable.com/appldCQKnuM5TG2Mz/shrrhQZfL9VnXX5C4';
export const EMERGENCY_SUPPORT_TEAM = 'Emergency Support Team';

export const PROGRAM_DELIVERIES = ['Day', 'Residential', 'Online'];

export const ACCEPTANCE_SURVEY_PAGES = {
  YOUR_JOURNEY: 'YourJourney',
  RESOURCES_AND_TOOLS: 'Resources&Tools',
  REFERRAL: 'Referral',
}
