import { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  IconButton,
  Grid,
} from '@material-ui/core';

import Fonts from '../../../common/fonts';
import DIALOG_CLOSE_ICON from '../../../img/dialog-close-icon-new.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookingUnavailable: {
      padding: '31px 34px 45px',

      [theme.breakpoints.down('md')]: {
        padding: '20px 16px',
      },

      '& .dialogHeading': {
        fontWeight: 600,
        fontSize: 24,
        letterSpacing: '-0.019em',
        color: '#000000',
        fontFamily: Fonts.POPPINS_FONT,
      },

      '& .dialogText': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#525454',
        fontFamily: Fonts.INTER,
      },

      '& a': {
        color: '#2F80ED',
        borderBottom: '1px solid #2F80ED',
      },
    },
  })
);
interface Props extends RouteComponentProps {
  open: boolean;
  isAllVideoChatHoursUsed: boolean;
  setOpen: Function;
  isRisk: boolean;
}

const BookingUnavailableDialog: FC<Props> = ({ open, isAllVideoChatHoursUsed, setOpen, isRisk, history }) => {
  const classes = useStyles();

  const handleClose = () => {
    history.replace('/home');
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <Grid className={classes.bookingUnavailable}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={2}
          >
            <Typography variant="h4" className="dialogHeading">
              Booking Unavailable
            </Typography>

            <IconButton onClick={handleClose}>
              <img src={DIALOG_CLOSE_ICON} alt="close" />
            </IconButton>
          </Box>
          { isRisk ? 
            <Typography variant="body1" className="dialogText">
              <>
              <div><b>Your account balance is overdue.</b></div>
              <div>Booking advisor meetings and milestone teams will be temporarily disabled  until the past-due balance is paid. If you have any question, please contact us at <a href="mailto:success@collegeadvisor.com" className="emailto">success@collegeadvisor.com</a></div>
              </>
            </Typography>
            :
            <Typography variant="body1" className="dialogText">
              {isAllVideoChatHoursUsed ? (
                <>
                  Please contact your advisor to discuss adding more time to your
                  package.
                </>
              ) : (
                <>
                  You are currently unable to book a meeting online with this
                  advisor. Please contact{' '}
                  <a href='tel:"+1 (855) 412-6272"'>+1 (855) 412-6272</a> for
                  assistance.
                </>
              )}
            </Typography>
          }
        </Grid>
      </Dialog>
    </>
  );
};

export default withRouter(BookingUnavailableDialog);
