import gql from 'graphql-tag';

export const ALL_COLLEGES_FILTER_QUERY = gql`
  query SearchCollegeWithFilterQuery(
    $name: String
    $collegeId: Int
    $metroAreaId: Int
    $StateId: Int
    $majors: [Int]
    $areaOfInterest: [Int]
    $acceptMin: Int
    $acceptMax: Int
    $tutionMin: Int
    $tutionMax: Int
    $satMin: Int
    $satMax: Int
    $actMin: Int
    $actMax: Int
    $internationalMin: Int
    $internationalMax: Int
    $sortCategory: String
    $sortOrder: String
    $page: Int
    $acceptCommonApp: Boolean
    $acceptCoalitionApp: Boolean
    $publicCollege: Boolean
    $privateCollege: Boolean
    $SchoolSizeSmall: Boolean
    $SchoolSizeMedium: Boolean
    $SchoolSizeLarge: Boolean
    $SchoolSizeVeryLarge: Boolean
    $rankingCategoryId: Int
    $rankingSubCategoryId: Int
  ) {
    searchCollegeWithFilter(
      input: {
        name: $name
        collegeId: $collegeId
        metroAreaId: $metroAreaId
        StateId: $StateId
        majors: $majors
        areaOfInterest: $areaOfInterest
        acceptMin: $acceptMin
        acceptMax: $acceptMax
        tutionMin: $tutionMin
        tutionMax: $tutionMax
        satMin: $satMin
        satMax: $satMax
        actMin: $actMin
        actMax: $actMax
        internationalMin: $internationalMin
        internationalMax: $internationalMax
        sortCategory: $sortCategory
        sortOrder: $sortOrder
        page: $page
        acceptCommonApp: $acceptCommonApp
        acceptCoalitionApp: $acceptCoalitionApp
        publicCollege: $publicCollege
        privateCollege: $privateCollege
        SchoolSizeSmall: $SchoolSizeSmall
        SchoolSizeMedium: $SchoolSizeMedium
        SchoolSizeLarge: $SchoolSizeLarge
        SchoolSizeVeryLarge: $SchoolSizeVeryLarge
        rankingCategoryId: $rankingCategoryId
        rankingSubCategoryId: $rankingSubCategoryId
      }
    ) {
      nextRecord
      colleges {
        id
        name
        logo
        address
        applicationRequirement {
          id
          gradeOverall
          acceptRate
          netPrice
          regularDecisionDeadline
          earlyActionDeadline
          earlyDecisionDeadline
          outStateTution
          privateTuition
          tuitionFee
          offersEarlyDecision
          offersEarlyAction
        }
        advisorCount
        webinarsCount
        applicationCreated
        parentMetaData
        ipedId
        ftStudents
        collegeRanking(
          input: {
            sortCategory: $sortCategory
            sortOrder: $sortOrder
            rankingCategoryId: $rankingCategoryId
            rankingSubCategoryId: $rankingSubCategoryId
          }
        ) {
          id
          CollegeId
          RankingValue
          rankingIsTied
          RankingAssociation {
            id
            RankingCategoryId
            RankingSubCategoryId
            RankingCategory {
              isParent
              name
            }
            RankingSubCategory {
              name
              isParent
            }
          }
        }
      }
      collegesCount
    }
  }
`;
export const ALL_COLLEGES_QUERY = gql`
  query AllCollegeQuery(
    $limit: Int
    $page: Int
    $searchQuery: String
    $collegesToExcludeIds: [Int]
  ) {
    allColleges(
      input: {
        limit: $limit
        page: $page
        searchQuery: $searchQuery
        collegesToExcludeIds: $collegesToExcludeIds
      }
    ) {
      nextRecord
      collegesCount
      colleges {
        id
        name
        logo
        applicationCreated
        customCollege
      }
    }
  }
`;
export const LOCATION_QUERY = gql`
  query locationStatesCityQuery($searchQuery: String, $limit: Int) {
    getMetroArea(searchQuery: $searchQuery, limit: $limit) {
      id
      name
      type
    }
  }
`;

export const MOST_POPULAR_MAJORS_QUERY = gql`
  query mostPopularMajorsQuery(
    $searchQuery: String
    $limit: Int
    $exclude: [Int]
  ) {
    getMostPopularMajors(
      searchQuery: $searchQuery
      limit: $limit
      exclude: $exclude
    ) {
      id
      name
      searchName
    }
  }
`;

export const SUPER_MAJOR_CATEGORY_QUERY = gql`
  query getSuperMajorCategoriesQuery(
    $searchQuery: String
    $limit: Int
    $exclude: [Int]
  ) {
    getSuperMajorCategories(
      searchQuery: $searchQuery
      limit: $limit
      exclude: $exclude
    ) {
      id
      displayName
    }
  }
`;

export const SUPER_MAJOR_CATEGORY_QUERY_WITH_ASSOCIATED_ADVISORS = gql`
  query getSuperMajorCategoriesWithAssociatedAdvisorsQuery(
    $searchQuery: String
    $limit: Int
    $exclude: [Int]
  ) {
    getSuperMajorCategoriesWithAssociatedAdvisors(
      searchQuery: $searchQuery
      limit: $limit
      exclude: $exclude
    ) {
      id
      displayName
    }
  }
`;

export const COLLEGE_RANKING_CATEGORIES_QUERY = gql`
  query allCollegesRankingCategoriesQuery($searchQuery: String, $limit: Int) {
    allCollegesRankingCategories(searchQuery: $searchQuery, limit: $limit) {
      id
      name
      isParent
    }
  }
`

export const COLLEGE_RANKING_SUB_CATEGORIES_QUERY = gql`
  query collegesRankingSubCategoriesQuery($searchQuery: String, $limit: Int, $categoryId: Int!) {
    collegesRankingSubCategories(searchQuery: $searchQuery, categoryId: $categoryId, limit: $limit) {
      id
      RankingCategoryId
      RankingSubCategoryId
      RankingCategory {
        name
        isParent
      }
      RankingSubCategory {
        name
        isParent
      }
    }
  }
`;