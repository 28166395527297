import { FC, useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  createStyles,
  Divider,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';

import GetStartAdvisorCard, {
  CALENDAR_IC,
  VIDEO_IC,
} from './GetStartAdvisorCard';

import client from '../../../apollo';
import {
  associationTypeOptions,
  ClientStatus,
  COLLEGE_AID_PRO,
  CONSULTANT_LEVEL,
} from '../../../common/constants';
import { sortAdvisorsWithLevel } from '../../../common/utility';
import { AppContext } from '../../../contexts';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from '../home/BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';

import Colors from '../../../common/colors';
import fonts from '../../../common/fonts';

import CelebrateIcon from '../../../img/celebrate-icon.svg';
import LINK_COPIED_ICON from '../../../img/link-copied-icon.png';
import LINK_ICON from '../../../img/link-copy.png';
import {
  getAllStudentMentors_allStudentMentors,
  getAllStudentMentors_allStudentMentors_consultant,
} from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import GetStartConsultantCard from './GetStartConsultantCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 32,
      padding: '33px 30px',
      borderRadius: 25,
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [theme.breakpoints.down('sm')]: {
        borderRadius: 15,
      },

      '& .scheduledDate': {
        fontWeight: 600,
        fontSize: 18,
        color: '#272929',
        letterSpacing: '-0.014em',
        marginBottom: 3,
        fontFamily: fonts.INTER,
      },

      '& .withAdvisorName': {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#7D7F7F',
        fontFamily: fonts.INTER,
      },

      '& .heading': {
        marginBottom: 5,
      },

      '& .description': {
        marginBottom: 22,
        color: '#7D7F7F',
      },

      '& .orientationCall': {
        marginBottom: 33,

        '&:disabled, &.Mui-disabled': {
          pointerEvents: 'initial',
          cursor: 'not-allowed !important',
        },
      },

      '@media only screen and (max-width: 767px)': {
        marginBottom: 17,
        padding: '15px',
      },
    },

    joinMeeting: {
      padding: '18px 0px 27px',

      [theme.breakpoints.down('xs')]: {
        padding: '20px 0px',
      },

      '& .iconDisabled': {
        cursor: 'not-allowed',
      },
    },

    linkFormCopied: {
      background: `${Colors.GREEN_TEN} !important`,
      opacity: '1 !important',
      color: '#ffffff !important',
    },

    linkForm: {
      minWidth: 208,
      height: 40,
      background: '#45CE93',
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: '-0.006em',
      color: '#FFFFFF',
      fontFamily: fonts.POPPINS_FONT,
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: '#38A878',
      },

      '& img': {
        marginRight: 10,
      },
    },
  })
);

const QontoConnector = withStyles({
  line: {
    height: 3,
    borderRadius: 10,
    borderColor: 'transparent',
  },

  lineHorizontal: {
    borderTopWidth: 0,
    background: Colors.WHITE_SEVEN,
  },

  active: {
    '& $line': {
      background: Colors.GREEN_EIGHT,
    },
  },

  completed: {
    '& $line': {
      background: Colors.GREEN_EIGHT,
    },
  },
})(StepConnector);

const getSteps = () => {
  return ['Intake Forms', 'Matching', 'Orientation Call'];
};

const getStepContent = (
  stepIndex: number,
  kickOffMeetingScheduled: boolean,
  isMBAProgram?: boolean
) => {
  switch (stepIndex) {
    case 0:
      return {
        heading: 'Get Started',
        body: 'Complete your student and parent intake forms to get matched to your Advisor.',
      };
    case 1:
      return {
        heading: 'Your Advisor is On The Way!',
        body: 'Thank you for completing your intake forms. You will be notified when you are matched with an Advisor.',
      };
    case 2:
      return {
        heading: (
          <>
            <img src={CelebrateIcon} alt="icon" className="m-0" /> You’ve Been
            Matched!
          </>
        ),
        body: `Begin your ${
          isMBAProgram ? 'MBA' : 'college'
        } admissions journey by planning your first meeting with your new Advisor!`,
      };
    default:
      return {
        heading: 'Unknown stepIndex',
        body: 'Unknown stepIndex',
      };
  }
};

interface advisorsWithKickOffCallInterFace
  extends allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings {
  advisorName: string;
  pictureUrl: string;
}

interface Props extends RouteComponentProps {
  handleCalendarClick: Function;
  advisorBookings:
    | (allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null)[]
    | null
    | undefined;
  refetch: Function;
  setIsMeetingBooked: Function;
  isMeetingBooked: boolean;
  isMBAProgram?: boolean;
  consultant?: getAllStudentMentors_allStudentMentors_consultant | null;
  isRisk: boolean;
}

const GetStartCard: FC<Props> = ({
  handleCalendarClick,
  advisorBookings,
  history,
  refetch,
  setIsMeetingBooked,
  isMeetingBooked,
  isMBAProgram = false,
  consultant = null,
  isRisk,
}) => {
  const { user } = useContext(AppContext);
  const [isCopied, setIsCopied] = useState(false);
  const classes = useStyles();
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const intakeResponse = JSON.parse(
    JSON.parse(JSON.stringify(user?.intakeResponse))
  );
  let step = 0;
  const completed =
    (user?.isParent && intakeResponse?.step === 3) ||
    (!user?.isParent && intakeResponse?.step === 6);
  if (completed) step = 1;
  if (allStudentMentors?.length) step = 2;

  const [activeStep, setActiveStep] = useState(step);
  const steps = getSteps();

  const isKick_Off_Meeting_Schedule: boolean =
    !!allStudentMentors?.length &&
    !!!user?.initialKickOffDate &&
    !!advisorBookings?.length;

  const meetingAdvisorids: (number | null)[] = [];
  const advisorsWithKickOffCall:
    | (advisorsWithKickOffCallInterFace | null)[]
    | null = [];

  if (advisorBookings?.length) {
    advisorBookings.map((item) =>
      item?.allParticipants?.map((p) => {
        if (
          p &&
          !meetingAdvisorids.find((advId) => advId === p?.id) &&
          p?.id !== user?.id
        )
          meetingAdvisorids.push(p?.id);
      })
    );

    advisorBookings.map((item) => {
      if (item && !advisorsWithKickOffCall.find((x) => x?.id === item?.id)) {
        const firstName =
          item.allParticipants?.find((p) => p?.id === item.AdvisorId)
            ?.firstName || '_ _';
        const lastName =
          item.allParticipants?.find((p) => p?.id === item.AdvisorId)
            ?.lastName || '_ _';
        const pictureUrl =
          item.allParticipants?.find((p) => p?.id === item.AdvisorId)
            ?.pictureUrl || '';

        advisorsWithKickOffCall.push({
          ...item,
          advisorName: `${firstName} ${lastName}`,
          pictureUrl,
        });
      }
    });
  }

  const handleLetsGo = () => {
    history.push(`intake-form/${user?.intakeFormToken}`);
  };

  const currentStepObject = getStepContent(
    activeStep,
    isKick_Off_Meeting_Schedule,
    isMBAProgram
  );

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });
      if (data && data.allStudentMentors) {
        setAllStudentMentors(data.allStudentMentors);
      }
      if (data.allStudentMentors.length) setActiveStep(2);
    };
    fetchAllStudentMentors();
  }, []);

  const handleRefetchMeetings = () => {
    const refetchMeetings = async () => {
      if (isMeetingBooked) {
        await refetch();
        setIsMeetingBooked(false);
      }
    };
    refetchMeetings();
  };

  useEffect(handleRefetchMeetings, [isMeetingBooked]);

  const isPremiumUser =
    user?.isPremium &&
    user?.clientStatus !== ClientStatus.BAD_DEBT &&
    user?.clientStatus !== ClientStatus.CANCELED;

  const hasMentors = user && allStudentMentors && allStudentMentors.length > 0;

  const hasAdditionalAdvisors = hasMentors
    ? allStudentMentors?.filter((record) =>
        associationTypeOptions.find(
          (option) => option.value === record?.associateType
        )
      )?.length
    : false;

  return (
    <Card className={classes.root}>
      <Typography variant="h4" className="heading">
        {currentStepObject.heading}
      </Typography>

      <Typography variant="body1" className="description">
        {
          getStepContent(activeStep, isKick_Off_Meeting_Schedule, isMBAProgram)
            .body
        }
      </Typography>

      {activeStep !== 2 && !advisorsWithKickOffCall.length && (
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {activeStep === 0 && (
        <Box pt={4}>
          <Button variant="contained" color="secondary" onClick={handleLetsGo}>
            Let’s Go!
          </Button>
        </Box>
      )}

      {isMBAProgram && <Divider />}

      {activeStep === 2 && (
        <Box mt={4}>
          {(!isPremiumUser || !advisorsWithKickOffCall.length) &&
            !isMBAProgram && (
              <Box marginBottom={4}>
                <Button
                  onClick={() => {
                    user &&
                      allStudentMentors &&
                      !isRisk &&
                      isPremiumUser &&
                      handleCalendarClick(allStudentMentors[0]?.mentor?.id);
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={!isPremiumUser || !!advisorsWithKickOffCall.length || isRisk}
                >
                  <Box component="span" mr={1.5}>
                    {CALENDAR_IC()}
                  </Box>
                  Schedule Orientation Call
                </Button>
              </Box>
            )}
          {!!advisorsWithKickOffCall?.length &&
            advisorsWithKickOffCall[0]?.startTime &&
            advisorsWithKickOffCall[0]?.endTime && (
              <>
                <Typography className="scheduledDate">{`${moment
                  .unix(advisorsWithKickOffCall[0]?.startTime)
                  .format('ddd MMM DD')}, ${moment
                  .unix(advisorsWithKickOffCall[0]?.startTime)
                  .format('LT')} ${moment
                  .tz(moment.tz.guess())
                  .format('z')}`}</Typography>
                <Typography className="withAdvisorName">
                  w/{' '}
                  {advisorsWithKickOffCall
                    ?.map((item) =>
                      item?.allParticipants
                        ?.filter(
                          (participant) =>
                            !participant?.roles?.includes('Student')
                        )
                        .map((x) => `${x?.firstName} ${x?.lastName}`)
                    )
                    .join(',')}
                </Typography>

                {moment
                  .unix(advisorsWithKickOffCall[0]?.startTime)
                  .isSameOrBefore(moment()) &&
                  moment
                    .unix(advisorsWithKickOffCall[0]?.endTime)
                    .isAfter(moment()) && (
                    <Box className={classes.joinMeeting}>
                      <a
                        href={advisorsWithKickOffCall[0]?.zoomLink || ''}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!isPremiumUser}
                          className={!isPremiumUser ? 'iconDisabled' : ''}
                        >
                          <Box component="span" mr={1.5}>
                            {VIDEO_IC()}
                          </Box>
                          Join Meeting
                        </Button>
                      </a>
                    </Box>
                  )}
                {moment
                  .unix(advisorsWithKickOffCall[0]?.startTime)
                  .isAfter(moment()) && (
                  <Box className={classes.joinMeeting}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isCopied}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          advisorsWithKickOffCall[0]?.zoomLink
                            ? advisorsWithKickOffCall[0]?.zoomLink
                            : ''
                        );
                        setIsCopied(true);
                        setTimeout(() => {
                          setIsCopied(false);
                        }, 5000);
                      }}
                      className={
                        isCopied
                          ? clsx(classes.linkFormCopied, classes.linkForm)
                          : classes.linkForm
                      }
                    >
                      {isCopied ? (
                        <>
                          <img src={LINK_ICON} alt="copied" /> Link Copied
                        </>
                      ) : (
                        <>
                          <img src={LINK_COPIED_ICON} alt="copied" />
                          Copy Meeting Link
                        </>
                      )}
                    </Button>
                  </Box>
                )}
              </>
            )}

          {!!allStudentMentors?.length &&
            sortAdvisorsWithLevel(allStudentMentors)?.map(
              (item: any, index) => {
                return !item?.isExtraMatch ? (
                  <>
                    <GetStartAdvisorCard
                      key={`start-card-${index}`}
                      advisorName={`${item?.mentor?.firstName || '--'} ${
                        item?.mentor?.lastName || '--'
                      }`}

                      advisorCollege={
                        item?.mentor?.Theme === 'graduation'
                          ? `${item?.mentor?.graduateSchool?.name || '--'}`
                          : `${item?.mentor?.currentCollege?.name || '--'}`
                      }
                      collegeYear={`${item?.mentor?.intakeYear || ''}`}
                      advisorImage={`${item?.mentor?.pictureUrl || ''}`}
                      collegeLogo={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        `${
                        item?.mentor?.graduateSchool?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`
                       : `${
                        item?.mentor?.currentCollege?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`

                      }
                      collegeColor={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        item?.mentor?.graduateSchool?.styleConfig?.bgDark ||
                        item?.mentor?.graduateSchool?.styleConfig?.bgLight ||
                        'blue'
                        :
                        item?.mentor?.currentCollege?.styleConfig?.bgDark ||
                        item?.mentor?.currentCollege?.styleConfig?.bgLight ||
                        'blue'
                      }

                      disableSheduleBtn={!!advisorsWithKickOffCall.length}
                      matchReason={item?.matchReason || ''}
                      advisorId={item?.mentor?.id || null}
                      mentorUuid={item?.mentor?.uuid || null}
                      advisorLevel={
                        item?.isExtraMatch
                          ? item?.associateType
                            ? associationTypeOptions.find(
                                (option) => option.value === item.associateType
                              )?.label || ''
                            : ''
                          : item?.mentor?.advisorLevel
                      }
                      bio={item?.mentor.bio}
                      isMBAProgram={isMBAProgram}
                    />
                  </>
                ) : null;
              }
            )}
          {!!consultant &&
            (!!advisorsWithKickOffCall?.length ||
              !isKick_Off_Meeting_Schedule) && (
              <GetStartConsultantCard
                consultantName={`${consultant?.firstName || '--'} ${
                  consultant?.lastName || '--'
                }`}
                consultantCollege={COLLEGE_AID_PRO}
                consultantImage={`${consultant?.imageUrl || ''}`}
                consultantLevel={CONSULTANT_LEVEL}
              />
            )}
          {(!isPremiumUser || !advisorsWithKickOffCall.length) &&
            isMBAProgram && (
              <Box marginBottom={4} textAlign="center" pl={8} marginTop={-5}>
                <Button
                  onClick={() => {
                    user &&
                      allStudentMentors &&
                      !isRisk &&
                      isPremiumUser &&
                      handleCalendarClick(allStudentMentors[0]?.mentor?.id);
                  }}
                  color="secondary"
                  variant="contained"
                  disabled={!isPremiumUser || !!advisorsWithKickOffCall.length || isRisk}
                >
                  <Box component="span" mr={1.5}>
                    {CALENDAR_IC()}
                  </Box>
                  Schedule Orientation Call
                </Button>
              </Box>
            )}
          {hasAdditionalAdvisors ? (
            <Box mt={1} mb={2}>
              <Typography variant="h6">SPECIALTY ADVISORS</Typography>
            </Box>
          ) : null}
          {!!allStudentMentors?.length &&
            sortAdvisorsWithLevel(allStudentMentors)?.map(
              (item: any, index) => {
                return item?.isExtraMatch ? (
                  <>
                    <GetStartAdvisorCard
                      key={`start-card-${index}`}
                      advisorName={`${item?.mentor?.firstName || '--'} ${
                        item?.mentor?.lastName || '--'
                      }`}

                      advisorCollege={
                        item?.mentor?.Theme === 'graduation'
                          ? `${item?.mentor?.graduateSchool?.name || '--'}`
                          : `${item?.mentor?.currentCollege?.name || '--'}`
                      }
                      collegeYear={`${item?.mentor?.intakeYear || ''}`}
                      advisorImage={`${item?.mentor?.pictureUrl || ''}`}
                      collegeLogo={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        `${
                        item?.mentor?.graduateSchool?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`
                       : `${
                        item?.mentor?.currentCollege?.logo ||
                        'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
                       }`

                      }
                      collegeColor={
                        item?.mentor?.Theme === 'graduation'
                        ? 
                        item?.mentor?.graduateSchool?.styleConfig?.bgDark ||
                        item?.mentor?.graduateSchool?.styleConfig?.bgLight ||
                        'blue'
                        :
                        item?.mentor?.currentCollege?.styleConfig?.bgDark ||
                        item?.mentor?.currentCollege?.styleConfig?.bgLight ||
                        'blue'
                      }
                      disableSheduleBtn={!!advisorsWithKickOffCall.length}
                      matchReason={item?.matchReason || ''}
                      advisorId={item?.mentor?.id || null}
                      mentorUuid={item?.mentor?.uuid || null}
                      advisorLevel={
                        item?.isExtraMatch
                          ? item?.associateType
                            ? associationTypeOptions.find(
                                (option) => option.value === item.associateType
                              )?.label || ''
                            : ''
                          : item?.mentor?.advisorLevel
                      }
                      bio={item?.mentor.bio}
                      diamondPackageDisplay
                      isMBAProgram={isMBAProgram}
                    />
                  </>
                ) : null;
              }
            )}
        </Box>
      )}
    </Card>
  );
};
export default withRouter(GetStartCard);
