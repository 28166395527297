import { CssBaseline } from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import gql from 'graphql-tag';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { convertTimeZoneForFE } from '../../../common/utility';
import Loader from '../../../components/Loader';
import { AppContext } from '../../../contexts';
import { Maintenance } from '../../errorPages';
import { mainTheme } from './mainTheme';
import client from '../../../apollo';

export const CURRENT_USER_QUERY = gql`
  query UserQuery {
    currentUser {
      id
      firstName
      lastName
      email
      isParent
      pictureUrl
      phoneNumber
      highSchool
      degree
      highSchoolYear
      applyingYear
      invitesSent
      gpa
      aidOption
      sat
      shareDriveUrl
      act
      isPremium
      graduated
      gapYear
      premiumPackageType
      premiumTotalEssayCount
      premiumEssayCountUsed
      premiumTotalVideoCount
      premiumVideoCountUsed
      isPackageCustom
      isAppNotificationOn
      createdAt
      intakeFormToken
      intakeResponse
      initialKickOffDate
      planCancelled
      markedPremiumAt
      isDisputed
      isPaymentFailed
      isRisk
      isMBAGrad
      undergraduateGradYear
      undergraduateCollege
      roles
      SmartMatchId
      studentTrial {
        id
        trialType
        trialStartedAt
        trialCompletedAt
      }
      cancelAction {
        type
        ARStatus
        amount
      }
      planDetail {
        discountCode
        totalAmountPaid
        amountRefunded
        amountDue
        totalAmount
      }
      sendbirdId
      firstMeetingBookedDate {
        id
        startTime
        endTime
      }
      parents {
        id
        firstName
        lastName
        email
        phoneNumber
        isPasswordSet
      }
      childrens {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      lastLocation {
        city
        country
        region
        timezone
      }
      userSubscription {
        id
        stripeSubscriptionId
        createdAt
        subscriptionPlanId
        SubscriptionPlan {
          id
          amount
          SubscriptionProduct {
            id
            shortName
            color
            name
          }
          AdmissionPackage {
            id
            shortName
            color
            name
            packageType
          }
          installments
          interval
        }
        SubscriptionInstallments {
          PaymentId
          startDate
          endDate
          stripeInvoiceId
          amount
          status
          chargedOn
        }
      }
      lastLoginAt
      origin
      createdInSalesforce
      userCoupon {
        id
        name
        percent_off
        amount_off
        stripeCouponId
        currency
        AdmissionPackageIds
      }
      unreadMessagesCount
      isOnline
      timezone
      lastPaymentStatus
      userToken
      premiumPackageColor
      signedContract {
        contractUrl
        FullName
        Initials
        DateSigned
        packageId
        discountCouponId
      }
      accountStatus
      clientStatus
      userLocation {
        city
        country
        state
        zipCode
      }
    }
  }
`;

const MainLayout: FC = ({ children }) => {
  const { setUser, isLoggedIn, unreadMessagesCount, setUnreadMessagesCount } =
    useContext(AppContext);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchAndSaveCurrentUser = async (shouldLoad: boolean) => {
    try {
      shouldLoad && setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        shouldLoad && setLoading(false);
        return null;
      }
      const { data } = await client.query({
        query: CURRENT_USER_QUERY,
        fetchPolicy: 'no-cache',
      });
      if (data) {
        setUser(
          data?.currentUser
            ? {
                ...data?.currentUser,
                timezone: convertTimeZoneForFE(data?.currentUser.timezone),
              }
            : null
        );
      }
      if (data?.currentUser) {
        document.cookie = '_caLoggedIn=true;domain=collegeadvisor.com';
      }
      if (data?.currentUser?.unreadMessagesCount && !unreadMessagesCount)
        setUnreadMessagesCount(data?.currentUser?.unreadMessagesCount);
      shouldLoad && setLoading(false);
    } catch (err: any) {
      setError(err?.message);
      shouldLoad && setLoading(false);
    }
  };

  useEffect(() => {
    const hasToken = localStorage.getItem('token');
    if (!hasToken && (window as any).showCookieNotice) {
      (window as any).showCookieNotice();
    }
  }, [location]);

  useEffect(() => {
    fetchAndSaveCurrentUser(true);
  }, []);
  if (loading) {
    return <Loader />;
  }

  if (error && window.location.pathname !== '/maintenance') {
    return <Maintenance />;
  }

  // TODO: Will have to remove it on Monday
  if (
    isLoggedIn &&
    (window.location.pathname === '/login' ||
      window.location.pathname.includes('/reset-password') ||
      window.location.pathname.includes('/invite') ||
      window.location.pathname === '/register')
  ) {
    fetchAndSaveCurrentUser(false);
  }
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <div>{children}</div>
    </ThemeProvider>
  );
};

export default MainLayout;
