import { createStyles, makeStyles, Theme } from '@material-ui/core';
// styles
import fonts from '../../../common/fonts';
import Colors from '../../../common/colors';
// assets
import VCCS_BANNER_IMG from '../../../img/vccs-widget-bg.png';

export const homePageStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountLockedAlert: {
      display: 'flex',
      borderRadius: 15,
      marginBottom: 45,
      background: '#FFEFEB',
      alignItems: 'flex-start',
      padding: '20px 13px 15px 16px',

      '& img': {
        marginRight: 10,
      },

      "& [class*='MuiTypography-h4']": {
        fontSize: '14px !important',
        padding: '0px !important',
        fontWeight: 600,
        color: '#272929 !important',
        letterSpacing: '-0.084px !important',
        fontFamily: `${fonts.POPPINS_FONT} !important`,
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '24px',
        margin: '4px 0px 0px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        color: Colors.BLACK_FOURTEEN,

        '& a': {
          color: Colors.BLACK_FOURTEEN,
          borderBottom: `1px solid ${Colors.BLACK_FOURTEEN}`,
        },
      },

      "& [class*='MuiIconButton-root']": {
        padding: 6,
      },
    },

    videoChatHoursUsed: {
      display: 'flex',
      borderRadius: 15,
      marginBottom: 35,
      alignItems: 'flex-start',
      padding: '16px 16px 20px 16px',
      background: Colors.WHITE_EIGHT,

      '& a': {
        borderBottom: `1px solid ${Colors.BLUE_SEVEN}`,
      },

      '& img': {
        marginRight: 20,
      },

      "& [class*='MuiTypography-h4']": {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 14,
        fontWeight: 400,
        margin: '4px 0px 0px',
        letterSpacing: '-0.006em',
        color: Colors.BLACK_FOURTEEN,
      },

      "& [class*='MuiIconButton-root']": {
        padding: 6,
      },
    },

    delinquentPayment: {
      display: 'flex',
      borderRadius: 15,
      marginBottom: 35,
      alignItems: 'flex-start',
      padding: '16px 16px 20px 16px',
      background: '#FFEFEB',

      '& a': {
        borderBottom: `1px solid ${Colors.BLUE_SEVEN}`,
      },

      '& img': {
        marginRight: 20,
        width: 18,
        marginTop: 5,
      },

      "& [class*='MuiTypography-h4']": {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '24px',
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 14,
        fontWeight: 400,
        margin: '4px 0px 0px',
        letterSpacing: '-0.006em',
        color: Colors.BLACK_FOURTEEN,
        fontFamily: fonts.INTER,
      },

      "& [class*='MuiIconButton-root']": {
        padding: 6,
      },
    },

    card: {
      minHeight: 218,
      borderRadius: 15,
      marginBottom: 42,
      overflow: 'hidden',
      position: 'relative',
      backgroundSize: '100% 100%',
      background: `url(${VCCS_BANNER_IMG})`,

      '&:hover $overlay': {
        opacity: 0.8,
      },

      [theme.breakpoints.down('xs')]: {
        minHeight: 'auto',
      },

      "& [class*='MuiTypography-root']": {
        color: Colors.WHITE_ONE,
      },

      "& [class*='MuiTypography-caption']": {
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.072px',
      },

      "& [class*='MuiTypography-h3']": {
        fontSize: 32,
        maxWidth: 315,
        fontWeight: 700,
        lineHeight: '32px',
        margin: '4px 0px 7px',
        letterSpacing: '-0.608px',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          fontSize: 13,
          lineHeight: '24px',
        },
      },

      "& [class*='MuiTypography-body2']": {
        fontWeight: 400,
        marginBottom: 15,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
      },

      "& [class*='MuiButton-text']": {
        height: 'auto',
        color: Colors.WHITE_ONE,
        padding: '0px !important',

        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          border: 0,
        },
      },
    },

    overlay: {
      top: 0,
      left: 0,
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      transition: 'opacity 0.3s ease-in-out',
      backgroundColor: 'rgba(255,255,255,0.1)',
    },

    content: {
      zIndex: 1,
      padding: 24,
      position: 'relative',
      color: theme.palette.common.white,

      [theme.breakpoints.down('xs')]: {
        padding: 10,
      },
    },

    upperClassHeader: {
      padding: '75px 0px 45px',

      '@media only screen and (max-width: 480px)': {
        padding: '34px 0px',
      },

      '& .graduateResponsive': {
        [`@media only screen and (max-width: 450px)`]: {
          flexDirection: 'column',
        },
      },

      '& .graduateResponsivePadding': {
        [`@media only screen and (max-width: 450px)`]: {
          paddingBottom: 15,
        },
      },
    },

    graduatedBox: {
      background: '#FFCD4B',
      borderRadius: 6,
      width: 112,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& img': {
        width: 12,
        marginRight: 8,
      },

      '& p': {
        fontWeight: 600,
        fontSize: 14,
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },
    },

    addParentAccount: {
      textAlign: 'right',

      '& a': {
        color: Colors.BLUE_SEVEN,
        borderBottom: `1px solid ${Colors.BLUE_SEVEN}`,
      },
    },

    addParentWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginTop: 21,

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'inherit',
      },
    },

    upgradeButton: {
      fontSize: '13px !important',
      height: 30,
      width: 115,
      color: Colors.WHITE_ONE,
      background: '#FDBD4E',
      boxShadow: '0px 0.526578px 7.37209px rgba(68, 68, 68, 0.14) !important',

      '& img': {
        marginRight: 12,
      },
    },

    homeScreenContainer: {
      '& .MuiGrid-item': {
        width: '100%',
      },
    },

    packageCardMobile: {
      '@media only screen and (max-width: 1024px)': {
        marginBottom: 30,
      },

      '@media only screen and (min-width: 1025px)': {
        display: 'none',
      },
    },

    packageCardDesktop: {
      '@media only screen and (max-width: 1024px)': {
        display: 'none',
      },
    },

    accessGoogleDrive: {
      display: 'flex',
      alignItems: 'center',
      padding: '21px 30px',
      marginBottom: 29,
      border: '1px solid #D4D4D4',
      borderRadius: 25,
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      [`@media only screen and (max-width: 414px)`]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      '& .accessText': {
        flex: 1,
        marginLeft: 20,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: '-0.011em',
        color: '#272929',
        fontFamily: fonts.INTER,

        [`@media only screen and (max-width: 414px)`]: {
          margin: '15px 0px 5px',
        },
      },
    },

    activityPendingCard: {
      border: 0,
      marginBottom: 31,
      boxShadow: 'none',
      padding: '28px 56px',
      background: '#EBF3FE',

      '@media only screen and (max-width: 767px)': {
        padding: '15px',
        margin: 15,
      },

      '& h5, button': {
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,
      },

      '& h5': {
        fontSize: 14,
        color: '#272929',
        fontWeight: 600,
        marginBottom: 4,
        letterSpacing: '-0.006em',
      },

      '& p': {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 12,
        color: '#7D7F7F',
        letterSpacing: '-0.006em',
      },

      '& button': {
        borderRadius: 12,
        height: 32,
        minWidth: 164,
        fontWeight: 700,
        padding: '8px 15px',
        textTransform: 'capitalize',
        letterSpacing: '-0.006em',
        fontFamily: fonts.POPPINS_FONT,

        '& span': {
          fontSize: 14,
        },
      },

      '& .buttonContained': {
        marginRight: 12,
        color: Colors.WHITE_ONE,
      },

      '& .buttonOutlined': {
        color: '#272929',
        border: '1px solid #D4D4D4',

        '&:hover': {
          color: '#272929',
          border: '1px solid #D4D4D4',
          backgroundColor: Colors.WHITE_ONE,
        },
      },
    },
  })
);

export const blogStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationsCard: {
      marginBottom: 43,
      borderRadius: 25,

      '@media only screen and (max-width: 767px)': {
        marginBottom: 17,
      },

      [theme.breakpoints.down('sm')]: {
        borderRadius: 15,
      },
    },

    applicationsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '15px 20px',
      },
    },

    collegeInfoWrapper: {
      display: 'flex',
      padding: '24px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      '&:hover': {
        background: Colors.GRAY_BOREDER,
      },

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        padding: '20px 15px',
      },
    },

    collegeLogo: {
      width: 280,
      height: 163,
      borderRadius: 15,
      marginRight: 30,
      overflow: 'hidden',

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top',
      },
    },

    blogText: {
      maxWidth: 282,

      '@media only screen and (max-width: 767px)': {
        paddingTop: 15,
      },
    },

    exploreMore: {
      padding: 19,
      textAlign: 'center',

      '& h6': {
        color: Colors.BLUE_SEVEN,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 15,
      },
    },

    blogInfoWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '21px 30px',
      borderBottom: `1px solid ${Colors.WHITE_SIX}`,

      [theme.breakpoints.down('sm')]: {
        padding: '20px 15px',
      },

      '&:hover': {
        background: Colors.GRAY_BOREDER,
      },

      '& p': {
        [theme.breakpoints.down('sm')]: {
          paddingRight: 25,
        },
      },
    },
  })
);
